import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import ProgressButton from '@/commoncomponents/progressButton/progressButton.vue';
import { ScreenText } from '@/lang/ScreenText';
import UIkit from 'uikit';

@Component({
    components:{
        'progressButton': ProgressButton,
    }
})

export class DeletePopUp extends Vue {
    private objScreenText: ScreenText = new ScreenText();

    @PropSync('isDeletePopupVisible') isDeletePopupVisibleSync !: boolean;
  
    @Watch('isDeletePopupVisible', { immediate: true })
    onIsVisible(isDeletePopupVisible: boolean) {
      const isElementShowing = UIkit.modal(this.$el) ? UIkit.modal(this.$el).isToggled() : false;
  
      if (isDeletePopupVisible && isElementShowing === false) {
        UIkit.modal(this.$el).show();
      }
      else if (isDeletePopupVisible === false && isElementShowing) {
        UIkit.modal(this.$el).hide();
      }
    }

    @Prop({default: ''})
    deletePopupButtonText!: string;

    @Prop({default: ''})
    component!: string;

    @Prop()
    deleteIds!:number;

    @Prop({default:''})
    componentName!:string;

    @Prop()
    apiResponseCode!: 0;

    @Prop()
    isValidate!: false;
    
    public getScreenText(key: string): string {
        return this.objScreenText.getScreenText(key);
    }

    closePopup(){
        this.isDeletePopupVisibleSync = false;
    }

    deleteData(){
        this.$emit("deleteData");
    }
}